@import 'assets/scss/mixins';

.currency {
  position: relative;
  width: 0.9rem;
  @include flexrc;
  justify-content: flex-end;
  background-color: #25262b;
  padding: 0.03rem 0.04rem 0.025rem 0;
  border: 0.01rem solid #93979c;

  span {
    min-width: 77.5%;
    text-align: right;
    font-weight: bold;
  }

  .ico-token {
    position: absolute;
    left: -0.125rem;
    width: 0.3rem;
    height: 0.3rem;
    background: var(--ico-token) center no-repeat;
    background-size: contain;
  }

  .ico-cp {
    position: absolute;
    left: -0.1rem;
    width: 0.25rem;
    height: 0.25rem;
    background: var(--ico-cp) center no-repeat;
    background-size: contain;
  }
}
