@import './variable.scss';

@font-face {
  font-family: 'Gunar';
  font-weight: 400;
  src: url($CDN + 'fonts/Gunar.ttf') format('truetype');
}

// @font-face {
//   font-family: 'Gunar';
//   font-weight: 500;
//   src: url($CDN + 'fonts/Gunar.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Gunar';
//   font-weight: 700;
//   src: url($CDN + 'fonts/Gunar.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Gunar';
//   font-weight: 800;
//   src: url($CDN + 'fonts/Gunar.ttf') format('truetype');
// }

@font-face {
  font-family: 'RefrigeratorDeluxe';
  font-weight: 400;
  src: url($CDN + 'fonts/Refrigerator Deluxe.otf') format('opentype');
}
