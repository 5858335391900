@import 'assets/scss/variable';
@import 'assets/scss/mixins';

.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal,
.react-responsive-modal-root {
  animation-fill-mode: forwards !important;
  top: 0 !important;
}

.react-responsive-modal-root {
  display: flex;
  align-items: center;
}

.react-responsive-modal-overlay {
  background: var(--bg_popup_overlay) center/100% 100% no-repeat !important;
  backdrop-filter: blur(0rem);
  overflow: hidden !important;

  &.overlay-toast {
    background: #00000080 !important;
  }
}

.react-responsive-modal-container {
  width: 13.34rem !important;
  height: 6.6rem !important;
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden !important;
}

.react-responsive-modal-modal {
  padding: 0rem 0.05rem 0.1rem 0.125rem !important;
  margin: 0 auto !important;
  max-width: none !important;
  box-shadow: none !important;
  outline: none;
}

.modal {
  position: relative;
  @include flexcc;
  background: var(--bg-toast) center/contain no-repeat;
  font-family: $base-font;
  font-size: 0.18rem;
  color: #fefefe;
  overflow: visible;

  &.popup-regular {
    width: 9rem;
    height: 4.7rem;
    background-color: transparent;
    background: var(--bg-popup) center/contain no-repeat;
  }

  .btn-close {
    position: absolute;
    top: 0.075rem;
    right: 0.05rem;

    .ico-close {
      width: 0.375rem;
      height: 0.375rem;
      background: var(--btn-close) center/contain no-repeat;
    }
  }

  .modal__container {
    width: 100%;
    height: 100%;

    .modal__codm {
      position: absolute;
      top: 0rem;
      left: -0.1rem;
      width: 0.5rem;
      height: 0.5rem;
      background: var(--ico-codm) center/contain no-repeat;
    }

    .modal__title {
      position: absolute;
      top: 0.05rem;
      left: 0.45rem;
      height: 0.3rem;
      @include flexrc;
      justify-content: flex-start;
      font-family: $second-font;
      font-size: 0.2rem;
    }

    .modal__content {
      width: 99%;
      height: 87.5%;
      margin-top: 5%;
    }
  }
}
