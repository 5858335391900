@import 'assets/scss/variable';
@import 'assets/scss/mixins';

.modal-inventory {
  .modal__container {
    .modal__content {
      position: relative;
      @include flexcc;
      margin-top: 0.4rem;

      h1 {
        font-size: 0.5rem;
        font-family: $second-font;
        color: #ffe842;
        margin-top: 0.05rem;
        margin-bottom: 0.1rem;
      }

      p {
        width: 40%;
        font-size: 0.15rem;
        text-align: center;
        line-height: 1.3;
        margin-bottom: 0.15rem;
      }

      .token {
        position: absolute;
        top: 0.25rem;
        right: 1.2rem;
      }

      .count {
        position: absolute;
        top: 0.85rem;
        right: 1.65rem;
        font-size: 0.125rem;
        color: #fce640;
      }

      .checkbox-wrapper {
        position: absolute;
        top: 1rem;
        right: 1.4rem;
        @include flexrc;
        cursor: pointer;

        span {
          font-size: 0.125rem;
          color: #fce640;
          margin-right: 0.075rem;
        }

        .checkbox {
          width: 0.15rem;
          height: 0.15rem;
          background-image: var(--img-box-popup);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          margin-bottom: 0.035rem;

          &.checked {
            background-image: var(--img-box-popup-checked);
          }
        }
      }

      .prize-wrapper {
        width: 80%;
        height: 1.9rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        margin-bottom: 0.2rem;
      }

      .btn-wrapper {
        width: 3.65rem;
        @include flexrc;
        justify-content: space-between;
      }
    }
  }
}
