@import 'assets/scss/variable';

button {
  font-family: $second-font;
  font-size: 0.19rem;
  text-transform: uppercase;
  color: white;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;

  &.regular {
    width: 1.7rem;
    height: 0.45rem;
    @include flexrc;
    background: var(--btn-grey) center/contain no-repeat;
    font-size: 0.225rem;
    font-weight: bold;
    color: #5c6066;

    &.yellow {
      background: var(--btn-yellow) center/contain no-repeat;
    }
  }

  &.small {
    width: 100%;
    height: 0.225rem;
    @include flexrc;
    background: var(--btn-yellow) center/100% 100% no-repeat;
    font-size: 0.15rem;
    font-weight: bold;
    color: #5c6066;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    filter: grayscale(1);

    &:active {
      filter: brightness(1);
    }
  }

  &:active {
    filter: brightness(1.1);
    transform: translate3d(0, 0.1vmax, 0);
  }
}
