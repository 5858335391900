@import 'assets/scss/variable';
@import 'assets/scss/mixins';

.modal-breach_door {
  .modal__container {
    .modal__content {
      @include flexcc;
      justify-content: flex-start;
      padding-top: 0.2rem;

      .title {
        width: 3rem;
        height: 0.6rem;
        background: var(--img-title-breach_door) center/contain no-repeat;
        margin-bottom: 0.2rem;
      }

      p {
        margin-bottom: 0.25rem;
        opacity: 1;
        transition: opacity 0.25s;

        span {
          color: #d3c63f;
        }

        &.hide {
          opacity: 0;
          transition: opacity 0s;
        }
      }

      .frame-wrapper {
        width: 82.5%;
        @include flexrc;
        justify-content: space-between;

        .frame-grenade {
          position: relative;
          width: 1.4rem;
          height: 1.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:nth-child(1) {
            background: var(--frame-1) center/contain no-repeat;
          }

          &:nth-child(2) {
            background: var(--frame-2) center/contain no-repeat;
          }

          &:nth-child(3) {
            background: var(--frame-3) center/contain no-repeat;
          }

          &:nth-child(4) {
            background: var(--frame-4) center/contain no-repeat;
          }

          &:nth-child(5) {
            background: var(--frame-5) center/contain no-repeat;
          }

          &.selected {
            &:nth-child(1) {
              background: var(--frame-1-selected) center/contain no-repeat;
            }

            &:nth-child(2) {
              background: var(--frame-2-selected) center/contain no-repeat;
            }

            &:nth-child(3) {
              background: var(--frame-3-selected) center/contain no-repeat;
            }

            &:nth-child(4) {
              background: var(--frame-4-selected) center/contain no-repeat;
            }

            &:nth-child(5) {
              background: var(--frame-5-selected) center/contain no-repeat;
            }

            filter: drop-shadow(0rem 0rem 0.125rem #b0a345);
            will-change: filter;
          }

          .granade {
            position: absolute;
            bottom: 0.325rem;
            width: 1rem;
            height: 0.95rem;
            @include flexcc;
            object-fit: contain;
          }

          .qty {
            position: absolute;
            top: 0.15rem;
            right: 0.2rem;
            font-size: 0.13rem;
            font-weight: bold;
          }

          .price {
            position: absolute;
            bottom: 0.075rem;
            width: 90%;
            @include flexrc;
            font-family: $second-font;
            font-weight: bold;
            color: #505050;

            .cp {
              width: 0.18rem;
              height: 0.18rem;
              background: var(--ico-cp) center/contain no-repeat;
              margin-right: 0.05rem;
            }
          }
        }
      }

      .btn-confirm {
        position: absolute;
        bottom: 0.6rem;
      }
    }
  }
}
