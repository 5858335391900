@import 'assets/scss/mixins';

.doors {
  position: relative;
  top: 0.5rem;
  left: 0.75rem;
  position: absolute;
  width: 8.7rem;
  @include flexcc;

  .btn-info {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 0.4rem;
    height: 0.4rem;
    background: var(--btn-info) center/contain no-repeat;
  }

  .steel {
    position: relative;
    width: 90%;
    height: 0.6rem;
    @include flexrc;
    background: var(--img-steel) center no-repeat;
    background-size: contain;
    margin-bottom: 0.1rem;

    .title {
      width: 50%;
      height: 100%;
      background: var(--img-title) center no-repeat;
      background-size: contain;
    }
  }

  .door-wrapper {
    width: 100%;
    height: 5.25rem;
    @include flexrc;
    justify-content: space-between;
    align-items: flex-start;

    .door {
      position: relative;
      width: 2.75rem;
      height: 4rem;
      @include flexcc;
      cursor: pointer;

      &.on {
        .lamp {
          top: 0.01rem;
          background: var(--img-lamp-on) center/contain no-repeat;
        }

        .checkbox {
          bottom: -0.08rem;
          background: var(--img-box-checked) center/contain no-repeat;
        }
      }

      &:nth-child(1) {
        .door-close {
          background: var(--img-door-close-1) center/contain no-repeat;
        }
      }

      &:nth-child(2) {
        .door-close {
          background: var(--img-door-close-2) center/contain no-repeat;
        }
      }

      &:nth-child(3) {
        .door-close {
          background: var(--img-door-close-3) center/contain no-repeat;
        }
      }

      .door-open {
        position: absolute;
        width: 100%;
        height: 100%;
        @include flexcc;
        background: var(--img-door-open) center/contain no-repeat;

        .prize {
          position: absolute;
          width: 90%;
          height: 90%;
          width: 40%;
          height: 40%;
          object-fit: contain;
        }
      }

      .door-close {
        position: absolute;
        width: 100%;
        height: 100%;

        &.hide {
          animation: fadeOut 1s;
          animation-delay: 2s;
          animation-fill-mode: forwards;
        }
      }

      .smoke-open {
        position: absolute;
        bottom: -3%;
        width: 120%;
        height: 120%;
        object-fit: contain;
        z-index: 1;
      }

      .lamp {
        position: absolute;
        top: 0rem;
        width: 0.4rem;
        height: 0.4rem;
        background: var(--img-lamp) center/contain no-repeat;
      }

      .checkbox {
        position: absolute;
        bottom: -0.075rem;
        width: 0.45rem;
        height: 0.45rem;
        background: var(--img-box) center/contain no-repeat;
        margin-left: 0.1rem;
      }
    }
  }

  .smoke-breach {
    position: absolute;
    bottom: 1rem;
    width: 120%;
    height: 80%;
    object-fit: contain;

    &.hide {
      visibility: hidden;
    }
  }

  .btn-wrapper {
    position: absolute;
    bottom: 0.3rem;
    @include flexrc;
    margin-left: 0.1rem;

    .btn-open,
    .btn-breach {
      font-size: 0.2rem;
      font-weight: bold;
      color: #636033;
    }

    .btn-open {
      width: 2rem;
      height: 0.6rem;
      @include flexrc;
      background: var(--btn-open) center/contain no-repeat;
      padding-bottom: 0.025rem;
      margin-right: 0.1rem;

      .ico-cp {
        width: 0.225rem;
        height: 0.225rem;
        background: var(--ico-cp) center/contain no-repeat;
        margin: 0 0.04rem 0 0.05rem;
      }

      .ori {
        color: #645b22;
        text-decoration: line-through;
        margin-right: 0.05rem;
        transform: scale(0.8);
      }
    }

    .btn-breach {
      position: relative;
      width: 2.2rem;
      height: 0.725rem;
      @include flexcc;
      background: var(--btn-breach) center/contain no-repeat;
      padding: 0 0 0.02rem 0.25rem;

      .glow {
        position: absolute;
        left: 14%;
        width: 80%;
        height: 55%;
        @include flexcc;
        border-radius: 0.15rem;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 90%;
          transform: translateX(0%);
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(128, 186, 232, 0) 99%,
            rgba(125, 185, 232, 0) 100%
          );
          animation: slide 2s infinite;
        }
      }
    }
  }

  .btn-inventory {
    position: absolute;
    bottom: 0.45rem;
    left: -0.25rem;
    width: 1.2rem;
    height: 0.35rem;
    background: var(--btn-inventory) center/contain no-repeat;
  }
}
