/* width */
::-webkit-scrollbar {
  width: 0.075rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--bar) center/100% 100% no-repeat;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bar-inner) center/100% 100% no-repeat;
}

// /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  filter: grayscale(1);
}
