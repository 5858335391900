@import 'assets/scss/mixins';

.exchange {
  top: 1.05rem;
  right: 0.55rem;
  position: absolute;
  width: 3.3rem;
  height: 4.75rem;
  animation: shadow 2.5s linear infinite;

  .btn-exchange {
    width: 100%;
    height: 100%;
    background: var(--btn-exchange) center/contain no-repeat;
  }
}
