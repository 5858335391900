.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10000;
  text-align: center;
  display: none;
  top: 0;
  left: 0;
}

.img {
  position: relative;
  width: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.active {
  display: block;
}
