@import 'assets/scss/variable';
@import 'assets/scss/mixins';

.modal-congrat {
  .modal__container {
    .modal__content {
      @include flexcc;
      margin-top: 0.45rem;

      h1 {
        font-size: 0.5rem;
        font-family: $second-font;
        color: #ffe842;
        margin-bottom: 0.1rem;
      }

      p {
        width: 75%;
        font-size: 0.15rem;
        text-align: center;
        line-height: 1.3;
        margin-bottom: 0.15rem;
      }

      .prize-wrapper {
        width: 80%;
        max-height: 2.125rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        margin-bottom: 0.15rem;
      }
    }
  }
}
