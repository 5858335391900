@import 'assets/scss/variable';

.frame {
  position: relative;
  width: 1.1rem;
  min-height: 1.45rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.05rem 0.1rem 0.05rem 0.1rem;

  &.disabled {
    filter: grayscale(1) brightness(0.5);

    .btn-exchange {
      pointer-events: none;
    }
  }

  &.Common {
    background: var(--box-grey) top/100% auto no-repeat;
  }

  &.Uncommon {
    background: var(--box-green) top/100% auto no-repeat;
  }

  &.Rare {
    background: var(--box-rare) top/100% auto no-repeat;
  }

  &.Epic {
    background: var(--box-epic) top/100% auto no-repeat;
  }

  &.Legend {
    background: var(--box-legend) top/100% auto no-repeat;
  }

  &.Legendary {
    background: var(--box-legend) top/100% auto no-repeat;
  }

  &.grandprize {
    background: var(--box-gp-fx) top/100% auto no-repeat;

    .prize {
      height: 65%;
    }

    .qty {
      top: 60%;
    }
  }

  &.selectable {
    cursor: pointer;

    .checkbox {
      display: flex;
    }
  }

  &.exc {
    height: 1.675rem;
    margin-bottom: 0.2rem;

    .prize {
      height: 45%;
    }

    .gp-fx {
      height: 63%;
    }

    .name {
      top: 68%;
    }

    .qty {
      top: 45%;
    }

    .btn-exchange {
      display: flex;
    }
  }

  .prize {
    position: absolute;
    top: 5%;
    width: 85%;
    height: 55%;
    @include flexcc;
    object-fit: contain;
  }

  .gp-fx {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 63%;
    height: 76%;
    @include flexcc;
    background: var(--box-gp-fx) center/contain no-repeat;
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }

  .checkbox {
    position: absolute;
    top: 1%;
    right: 0%;
    width: 0.225rem;
    height: 0.225rem;
    display: none;
    background: var(--img-box) center/contain no-repeat;
    margin-bottom: 0.035rem;

    &.checked {
      background: var(--img-box-checked) center/contain no-repeat;
    }
  }

  .limit {
    position: absolute;
    top: 2.5%;
    right: 5%;
    z-index: 1;
    font-family: $second-font;
    font-size: 0.15rem;

    &.hide {
      visibility: hidden;
    }
  }

  .total {
    position: absolute;
    top: -2.5%;
    right: -2.5%;
    z-index: 1;
    width: 0.2rem;
    height: 0.2rem;
    @include flexcc;
    font-family: $second-font;
    font-size: 0.15rem;
    font-weight: bold;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }

  .qty {
    position: absolute;
    top: 52%;
    z-index: 1;
    right: 5%;
    font-family: $second-font;
    font-size: 0.16rem;
  }

  .name {
    width: 1.9rem;
    position: absolute;
    top: 79%;
    font-size: 0.18rem;
    transform: scale(0.6);
    transform-origin: top;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.25;
  }

  .btn-exchange {
    position: absolute;
    bottom: 0%;
    display: none;
    font-size: 0.17rem;

    .ico-token {
      width: 0.215rem;
      height: 0.215rem;
      background: var(--ico-token) center/contain no-repeat;
      margin-right: 5%;
    }
  }
}
