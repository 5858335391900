@import 'assets/scss/mixins';

.modal {
  &.toast {
    width: 8.75rem;
    height: 0.6rem;
    background-color: transparent !important;
    background: var(--bg-toast) center/contain no-repeat;
    // background: var(--img-bg-toast) center/contain no-repeat;

    .modal__container {
      margin-top: 0.1rem;

      .modal__content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0%;

        &.toast {
          width: 100%;
          height: 100%;
          @include flexcc;

          p {
            font-size: 0.225rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
