@import 'variable';

body {
  width: 100%;
  height: 100%;
  background: var(--bg-main) center no-repeat;
  background-size: cover !important;
  background-color: #000;

  @include tabletDeviceScreen {
    background-size: auto 125% !important;
    background-position: bottom;
  }
}

#root {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 0.36rem;
}

#Main {
  position: fixed;
  margin: 0 auto;
  top: 0 !important;
  right: 0;
  left: 0;
  bottom: 0;
  width: 13.34rem;
  height: 6.6rem;

  @include tabletDeviceScreen {
    margin: auto;
  }

  &:before {
    content: '';
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content-container {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: $base-font;
    font-size: 0.18rem;
    color: #fefefe;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
  }
}

/* Modal layout setup */
.react-responsive-modal-root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.6rem;

  @include tabletDeviceScreen {
    height: 100%;
  }
}

.react-responsive-modal-overlay {
  overflow: hidden;
}

.react-responsive-modal-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 0.36rem;
}

.react-responsive-modal-modal {
  margin: 0 auto !important;
  max-width: none !important;
}
/* END SETUP FOR KEEP ASPECT RATIO VIEWPORT */
