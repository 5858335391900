@import 'assets/scss/variable';
@import 'assets/scss/mixins';

.modal-confirm {
  .modal__container {
    .modal__content {
      @include flexcc;
      padding-bottom: 0.75rem;

      h1 {
        font-size: 0.5rem;
        font-family: $second-font;
        color: #ffe842;
        margin-bottom: 0.3rem;
      }

      p {
        margin-bottom: 0.2rem;

        span {
          font-weight: bold;
          color: #d3c63f;
        }
      }

      .reminder {
        @include flexrc;
        cursor: pointer;

        .box {
          width: 0.25rem;
          height: 0.25rem;
          background: var(--img-box-popup) center/contain no-repeat;
          margin-right: 0.05rem;

          &.checked {
            background: var(--img-box-popup-checked) center/contain no-repeat;
          }
        }

        .text {
          color: #efdc4a;
          font-weight: bold;
          font-size: 0.16rem;
          margin-top: 0.075rem;
        }
      }

      .btn-wrapper {
        position: absolute;
        bottom: 0.7rem;
        width: 3.7rem;
        @include flexrc;
        justify-content: space-between;
      }
    }
  }
}
