@import 'assets/scss/variable';
@import 'assets/scss/mixins';

.modal-purchase {
  .modal__container {
    .modal__content {
      position: relative;
      @include flexcc;
      justify-content: flex-start;
      margin-top: 0.6rem;

      h1 {
        font-size: 0.5rem;
        font-family: $second-font;
        color: #ffe842;
        margin-bottom: 0.2rem;
      }

      .token {
        position: absolute;
        top: 0.1rem;
        right: 1.2rem;
      }

      .prize-wrapper {
        width: 80%;
        height: 2.6rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        margin-bottom: 0.15rem;
      }
    }
  }
}
