@import 'reset';
@import 'animation';
@import 'font';
@import 'functions';
@import 'mixins';
@import 'lang';
@import 'layout';
@import 'scrollbar';
@import 'variable';

@import '~/node_modules/slick-carousel/slick/slick.css';
@import '~/node_modules/slick-carousel/slick/slick-theme.css';

*:not(input) {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

html {
  position: fixed;
}

a {
  text-decoration: none;
}
