@import 'assets/scss/mixins';

.tooltip {
  min-width: 1rem;
  z-index: 1;
  background-color: #d9ffee;
  font-family: GFF Latin;
  font-weight: 700;
  font-size: 0.13rem;
  color: #1b5549;
  text-align: center;
  padding: 0.03rem 0.1rem;
}
