@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 175%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(50%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 175%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes shadow {
  0% {
    filter: drop-shadow(0rem 0rem 0rem #ffea3e);
  }
  50% {
    filter: drop-shadow(0rem 0rem 0.125rem #ffea3e);
  }
  100% {
    filter: drop-shadow(0rem 0rem 0rem #ffea3e);
  }
}

.shadow {
  animation-name: shadow;
  animation-iteration-count: infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes slide {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

.zoomInOut {
  animation-name: zoomInOut;
}

@keyframes zoomIn {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation-name: rotating;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: scale(1, 1) rotate(0deg);
  }
  33% {
    transform: scale(1.2, 1.2) rotate(-0deg);
  }
  66% {
    transform: scale(1.2, 1.2) rotate(0deg);
  }
  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fast {
  animation-duration: 500ms;
}

.faster {
  animation-duration: 250ms;
}

.infinite {
  animation-iteration-count: infinite;
}
