@import 'assets/scss/mixins';

.user-info {
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;

  .nickname {
    font-weight: bold;
    text-align: right;
    margin-bottom: 0.075rem;
  }

  .amount {
    width: 2.05rem;
    @include flexrc;
    justify-content: space-between;
  }
}
