@import 'assets/scss/variable';
@import 'assets/scss/mixins';

.modal-info {
  .modal__container {
    .modal__content {
      @include flexcc;
      justify-content: flex-start;

      .tabs {
        position: absolute;
        top: 0.45rem;
        @include flexrc;
        text-transform: uppercase;
        margin-top: 0.1rem;

        .tab {
          position: relative;
          width: 1.6rem;
          height: 0.3rem;
          @include flexcc;
          font-size: 0.175rem;
          font-weight: bold;
          color: #2d2a0f;
          background: var(--btn-tab) center/contain no-repeat;
          cursor: pointer;
          padding-top: 0.02rem;
          margin: 0.1rem -0.04rem 0.2rem -0.04rem;

          &:nth-child(1) {
            .mark {
              display: flex;
            }
          }

          &.active {
            color: #6b5b03;
            background: var(--btn-tab-on) center/contain no-repeat;
          }

          .mark {
            display: none;
            position: absolute;
            bottom: 0.125rem;
            right: 0;
            width: 0.25rem;
            height: 0.25rem;
            background: var(--ico-mark) center/contain no-repeat;

            &.hide {
              transition: all 0.5s;
              transform: scale(0);
            }
          }
        }
      }

      .content-wrapper {
        position: absolute;
        bottom: 0.4rem;
        width: 85%;
        height: 67.5%;
        display: flex;
        justify-content: center;
        animation: fadeIn 0.5s;

        .carousel {
          position: relative;
          width: 85%;
          height: 95%;

          img {
            height: 3rem;
            object-fit: contain;
          }

          .carousel-dots {
            width: 100%;
            position: absolute;
            bottom: -0.15rem;
            display: flex !important;
            justify-content: center;

            li {
              width: 0.1rem;
              height: 0.1rem;
              background: var(--dot) center/contain no-repeat;
              margin: 0 0.15rem;

              &.slick-active {
                background: var(--dot-on) center/contain no-repeat;
              }

              button {
                width: 100%;
                color: transparent;
              }
            }
          }

          .slick-arrow {
            position: absolute;
            width: 0.5rem;
            height: 0.5rem;
            @include flexcc;
            background: var(--btn-arrow) center/contain no-repeat;
            cursor: pointer;

            &::before {
              display: none;
            }
          }

          .slick-prev {
            z-index: 1;
            top: 1.1rem;
            left: -0.7rem;
            transform: scaleX(-1);
          }

          .slick-next {
            z-index: 1;
            top: 1.1rem;
            right: -0.7rem;
            transform: scaleX(1);
          }

          .slick-disabled {
            filter: grayscale(1);
            opacity: 0.75;
            touch-action: none;
            pointer-events: none;
          }
        }

        .event-info {
          width: 100%;
          height: 95%;
          font-size: 0.16rem;
          line-height: 1.5;
          padding-right: 0.5rem;
          overflow-y: auto;
          margin-bottom: 1rem;

          p {
            margin-bottom: 1%;
            padding-left: 1%;
          }

          ol {
            list-style-type: decimal;
            padding-left: 4%;

            li {
              padding-left: 1.5%;
              margin-bottom: 1%;

              a {
                color: unset;
                text-decoration: underline;
              }
            }
          }
        }

        .history {
          width: 100%;
          height: 3rem;
          @include flexcc;
          align-items: flex-start;
          justify-content: flex-start;
          padding-left: 0.75rem;

          thead,
          tbody {
            tr {
              font-family: $second-font;

              td {
                font-size: 0.2rem;
                text-align: center;
                vertical-align: middle;
                border: 0.01rem solid #aeb2b6;

                &:nth-child(1) {
                  width: 2rem;
                  text-transform: uppercase;
                }

                &:nth-child(2) {
                  width: 2rem;
                  text-transform: uppercase;
                }

                &:nth-child(3) {
                  width: 2rem;
                }
              }
            }
          }

          thead {
            color: #fce742;

            td {
              height: 0.5rem;

              &:nth-child(1) {
                border-left: 0.05rem solid #feed43;
              }
            }
          }

          tbody {
            height: 80%;
            overflow-y: auto;
            padding-right: 0.25rem;

            td {
              padding: 0.1rem;
              padding-top: 0.12rem;
              padding-bottom: 0.12rem;
            }
          }
        }
      }
    }
  }
}
